<template>
  <div class="wrap-box">
    <el-card class="box-card">
      <img class="out-img" :src="outUrl" alt="登出系统" title="登出系统" @click="logOut" />
      <div class="info-input-area">
        <h2 class="info-tit">实名信息录入</h2>
        <el-form
          class="demo-ruleForm"
          ref="ruleFormRef"
          :model="ruleForm"
          status-icon
          :hide-required-asterisk="true"
          :rules="rules"
        >
          <el-form-item prop="name">
            <el-input
              v-model.trim="ruleForm.name"
              type="text"
              placeholder="姓       名"
              autocomplete="off"
            >
              <template #prefix>
                <img :src="nameUrl" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="idCard">
            <el-input
              v-model.trim="ruleForm.idCard"
              type="text"
              placeholder="身份证号"
              autocomplete="off"
            >
              <template #prefix>
                <img :src="idUrl" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="readMe">
            <el-checkbox-group v-model="ruleForm.readMe">
              <el-checkbox>
                我已阅读并同意
                <a
                  class="read-a"
                  href="javascript:;"
                  name="readMe"
                  @click="dialogVisible = true"
                >{{title.includes('柳州') ? `《${docLzTitle}》` : `《${docNorTitle}》`}}</a>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitFormFn">提交</el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-dialog
      v-model="dialogVisible"
      :title="title.includes('柳州') ? docLzTitle : docNorTitle"
      width="50%"
      top="10vh"
      :show-close="false"
    >
      <div v-if="title.includes('柳州')" class="agree-info" oncontextmenu="return false">
        <b>本系统依据柳州市教育局的规定提供服务，本协议具有合同效力。您必须完全同意以下所有条款，才能保证享受到更好的柳州市高中教育云平台服务。您使用服务的行为将视为对本协议的接受，并同意接受本协议各项条款的约束。</b>
        <p>1、严格遵守保密管理工作相关法律法规，保守在工作中所涉及的秘密，保密有效时间与国家对各具体秘密事项的保密期限要求相一致。</p>
        <p>2、不得擅自记录、复制、拍摄、摘抄、收藏在工作中涉及的秘密和敏感信息；严禁将工作中涉及的相关基础数据或业务信息透露给无关人员。</p>
        <p>3、用户必须合法使用本平台服务，不作非法用途，自觉维护本网站的声誉，遵守所有使用网络服务的网络协议、规定、程序和惯例。</p>
        <p>4、为更好的为用户服务，用户应向本系统提供真实、准确的个人资料，个人资料如有变更，应立即修正。如因用户提供的个人资料不实或不准确，给用户自身造成任何性质的损失，均由用户自行承担。</p>
        <p>5、尊重个人隐私是本系统的责任，本系统在未经用户授权时不得向第三方公开、编辑或透露用户个人资料的内容，但由于政府要求、法律政策需要等原因除外。</p>
        <p>6、本系统有权在必要时修改服务条例，本系统的服务条例一旦发生变动，将会在本系统的重要页面上提示修改内容，用户如不同意新的修改内容，须立即停止使用本协议约定的服务，否则视为用户完全同意并接受新的修改内容。根据客观情况及经营方针的变化，本系统有中断或停止服务的权利，用户对此表示理解并完全认同。</p>
        <p>7、本保密协议的解释权归柳州市教育局所有。</p>
      </div>
      <div v-else class="agree-info" oncontextmenu="return false">
        <b>本系统依据主管部门的规定提供服务，本协议具有合同效力。您必须完全同意以下所有条款，才能保证享受到更好的教育云平台服务。您使用服务的行为将视为对本协议的接受，并同意接受本协议各项条款的约束。</b>
        <p>1、严格遵守保密管理工作相关法律法规，保守在工作中所涉及的秘密，保密有效时间与国家对各具体秘密事项的保密期限要求相一致。</p>
        <p>2、不得擅自记录、复制、拍摄、摘抄、收藏在工作中涉及的秘密和敏感信息；严禁将工作中涉及的相关基础数据或业务信息透露给无关人员。</p>
        <p>3、用户必须合法使用本平台服务，不作非法用途，自觉维护本网站的声誉，遵守所有使用网络服务的网络协议、规定、程序和惯例。</p>
        <p>4、为更好的为用户服务，用户应向本系统提供真实、准确的个人资料，个人资料如有变更，应立即修正。如因用户提供的个人资料不实或不准确，给用户自身造成任何性质的损失，均由用户自行承担。</p>
        <p>5、尊重个人隐私是本系统的责任，本系统在未经用户授权时不得向第三方公开、编辑或透露用户个人资料的内容，但由于政府要求、法律政策需要等原因除外。</p>
        <p>6、本系统有权在必要时修改服务条例，本系统的服务条例一旦发生变动，将会在本系统的重要页面上提示修改内容，用户如不同意新的修改内容，须立即停止使用本协议约定的服务，否则视为用户完全同意并接受新的修改内容。根据客观情况及经营方针的变化，本系统有中断或停止服务的权利，用户对此表示理解并完全认同。</p>
        <p>7、本保密协议的解释权归提供教育云平台的服务商所有。</p>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" round @click="confirmFn">确&nbsp;&nbsp;&nbsp;&nbsp;认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref, nextTick, watch } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import router from "@/router";
import { useStore } from "vuex";
import Cookies from "js-cookie";
import { rmCertSso } from "@/api/user.js";
import { getPwd, removePwd } from "@/utils/auth";
import * as ENVS from "@/env";

export default {
  setup() {
    // 定义使用vuex
    const store = useStore();

    const state = reactive({
      dialogVisible: false,
      title: ENVS.TITLE,
      outUrl: require("@/assets/idcard-images/out.png"),
      docLzTitle: "柳州市高中教育云平台用户保密协议",
      docNorTitle: "教育云平台用户保密协议",
      nameUrl: require("@/assets/idcard-images/111.png"),
      idUrl: require("@/assets/idcard-images/222.png"),
      ruleForm: {
        name: "",
        idCard: "",
        readMe: []
      },
      checkId: function(rule, value, callback) {
        const regId = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (!regId.test(value)) {
          callback(new Error("字符长度或者格式不正确"));
        } else {
          callback();
        }
      }
    });

    // 定义表单校验规则
    const rules = {
      name: [
        {
          required: true,
          message: "请输入真实姓名",
          trigger: "blur"
        }
      ],
      idCard: [
        {
          required: true,
          message: "请输入身份证号码",
          trigger: "blur"
        },
        {
          validator: state.checkId,
          trigger: "blur"
        }
      ],
      readMe: [
        {
          type: "array",
          required: true,
          message: "请勾选",
          trigger: "change"
        }
      ]
    };

    // 手动输入地址栏网址...
    if (
      !store.state.loginInfo.userInfo.needRMCert ||
      store.state.certTrue == "true"
    ) {
      router.push("/sso");
    }

    // 获取表单组件实例
    const ruleFormRef = ref(null);
    // 提交实名验证
    const submitFormFn = () => {
      ruleFormRef.value.validate(async isOK => {
        if (isOK) {
          // 信息录入正确
          try {
            const { data: res } = await rmCertSso({
              account: store.state.loginInfo.userInfo.account,
              password: getPwd(),
              idnumber: state.ruleForm.idCard,
              name: state.ruleForm.name
            });
            if (res.code == 0) {
              store.commit("setCertSso", "true");
              ElMessage.success("实名认证通过,为您跳转应用导航页");
              removePwd();
              router.push("/sso");
            } else if (res.code == 301) {
              ElMessage.error("用户姓名未通过验证");
            } else if (res.code == 302) {
              ElMessage.error("身份证号码未通过验证");
            } else {
              ElMessage.error("实名认证失败");
            }
          } catch (error) {
            console.log(error);
          }
          ruleFormRef.value.resetFields(); // 重置表单
        }
      });
    };

    // 确认告知通告
    const confirmFn = () => {
      state.ruleForm.readMe = [false];
      state.dialogVisible = false;
    };

    // 表单重置
    const resetForm = () => {
      ruleFormRef.value.resetFields(); // 重置表单
    };
    // 退出
    const logOut = () => {
      ElMessageBox.confirm(
        "您即将登出系统, 请确认是否执行该操作? ",
        "退出系统",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          if (Object.keys(Cookies.get()).length) {
            for (let k in Cookies.get()) {
              if (k == "sso-user") {
                continue;
              }
              Cookies.remove(k);
            }
          }
          store.commit("removeSsoToken");
          store.commit("removeLoginInfo");
          store.commit("removeCertSso");
          removePwd();
          router.push("/login");
          ElMessage.success("登出成功");
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消登出操作"
          });
        });
    };
    onMounted(() => {});
    return {
      ...toRefs(state),
      ruleFormRef,
      rules,
      submitFormFn,
      resetForm,
      confirmFn,
      logOut
    };
  }
};
</script>

<style lang="less" scoped>
.wrap-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f5f7;
}
// 弹框同意书
:deep .el-dialog {
  height: 80%;
  color: #333;
  overflow: auto;
  user-select: none;
  .el-dialog__body {
    padding-top: 10px;
    padding-left: 30px;
  }
}
:deep .el-dialog__title {
  line-height: 55px;
  font-size: 24px;
  font-weight: 600;
}
:deep .el-dialog__footer {
  display: flex;
  .dialog-footer {
    width: 100%;
    text-align: center;
    .el-button {
      width: 95%;
      font-size: 24px;
    }
  }
}
.agree-info {
  text-align: left;
  font-size: 18px;
}
// 卡片区域
.box-card {
  width: calc(1730px / 1.7);
  height: calc(1136px / 1.7);
  margin: 50px auto 50px;
  box-sizing: border-box;
  background: url("~@/assets/idcard-images/idbg.png");
  background-size: 100% 100%;
  :deep .el-card__body {
    position: relative;
    padding: 0;
    width: 100%;
    height: 100%;
    .out-img {
      position: absolute;
      right: 25px;
      width: 45px;
      top: 15px;
      cursor: pointer;
    }
    .info-input-area {
      position: absolute;
      top: 120px;
      right: 65px;
      width: 42%;
      .info-tit {
        line-height: 42px;
        font-size: 34px;
        font-weight: 400;
        color: #338acd;
        margin: 0;
        margin-top: 30px;
      }
    }
  }
  .el-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 45px 0 0;
    padding: 0;
    box-sizing: border-box;
    .el-form-item {
      :deep .el-input__prefix {
        display: flex;
        align-items: center;
        justify-content: center;
        left: 13px;
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        img {
          height: 52%;
        }
      }
      &:nth-child(3) {
        :deep .el-form-item__content {
          text-align: left;
          padding-left: 10px;
          box-sizing: border-box;
          .el-checkbox__label {
            color: #6e6d6d;
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            &:hover {
              color: #0000ee;
            }
          }
          .el-checkbox__inner {
            width: 16px;
            height: 16px;
            border-color: #409eff;
            &::after {
              width: 36%;
              height: 55%;
            }
          }
          .el-form-item__error {
            top: 75%;
          }
          .read-a {
            color: #409eff;
            text-decoration: none;

            text-overflow: ellipsis;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      &:last-child {
        :deep .el-form-item__content {
          display: flex;
          justify-content: space-between;
          .el-button {
            flex: 1;
            height: 50px;
            border-radius: 22px;
            border-color: #409eff;
            box-sizing: border-box;
            box-shadow: 1px 1px 10px 0 rgba(48, 113, 255, 0.5);
            &:first-child {
              background-image: linear-gradient(
                to right,
                rgb(121, 200, 252),
                rgb(48, 113, 255)
              );
              border: none;
            }
          }
        }
      }
    }
    :deep .el-input__inner {
      height: 50px;
      font-size: 18px;
      border-radius: 25px;
      padding-left: 65px;
      border: 1px solid #8ab9ff;
      background-color: #e6f0fa;
      box-sizing: border-box;
      &::placeholder {
        color: #b0b0b3;
        font-size: 16px;
      }
    }
  }
}
</style>